<script>
//import Multiselect from "vue-multiselect";
import {required,} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import "vue-multiselect/dist/vue-multiselect.min.css";
import ZonaService from "@/services/ZonaService";
const itemService = new ZonaService();


import InfoModel from "@/classes/zona";
const infoModel = new InfoModel();

import HelperJS from "@/functions/general";
const helper = new HelperJS();

/**
 * Add-product component
 */
const nameSeccion = 'Zona';
//const namePlural = nameSeccion+'s';
const routeParentName = 'zonas';

export default {
  page: {
    title: nameSeccion,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: nameSeccion,
      actionCreate: true,
      info_page:{
        routeParentName: null,
        routeBack: null,
        nameSeccion: null,
      },
      categorias: [],
      tipos: [],
      objectData:{},
      items: [
        {
          text: null,
        },
        {
          text: null,
          active: true,
        },
      ],
      submitted: false,

      
    };
  },
  validations: {
      objectData: {  
      name: {
          required,
        },
        
      slug: {
          required,
        }
      },
    },
  middleware: "authentication",
  
  created(){

    this.initPage();
  },
  
  methods: {
    async initPage(){
        
        this.info_page.routeParentName = routeParentName;
        this.info_page.nameSeccion = nameSeccion;
        this.info_page.routeBack = '/'+routeParentName;
        let text_page = 'Agregar '
        
        this.objectData = JSON.parse(JSON.stringify(infoModel.fillable));
        if(this.$route.name===nameSeccion+'Edit'){          
          this.actionCreate = false;
          text_page = 'Editar';

          itemService.getById(this.$route.params.id).then(async response=>{  
            let data = response.data.object_data;
            this.objectData =   JSON.parse(JSON.stringify(new InfoModel(data)));
          })
        }
        this.items[0].text= nameSeccion
        this.items[1].text= text_page+' '+nameSeccion;
      },
      async updateForm(){
      
          let formData = JSON.parse(JSON.stringify(this.objectData));
          if(this.actionCreate){
            delete formData.id;
            await itemService.create(formData)
          }else{
            await itemService.update(formData)
          }
          this.successForm(this.info_page.routeBack);
      
      },

      successForm(route){
        Swal.fire("¡Operación exitosa!", "Información guardada con éxito", "success").then(() => {
        this.$router.push({
              path: route,
            });
        });
    },
    cancelForm(){
      this.$router.push({
        path: this.infoPage.info_page,
      });
    },
      
      validateForm(){

        this.submitted = true;
       
        if(this.$v.$error===false &&  this.$v.$invalid===false){
          this.updateForm();
        }
        
      this.$v.$touch();
      },
      slugify(stringValue){

        return helper.convertToSlug(stringValue);
      },
     routeGo(textRoute){
      this.$router.push({
        path: textRoute,
      });
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div id="addproduct-accordion" class="custom-accordion">
          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="true"
              aria-controls="addproduct-billinginfo-collapse"
              v-b-toggle.accordion-1
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        01
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Información general</h5>
                    <p class="text-muted text-truncate mb-0">
                      Completa todos los datos
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              data-parent="#addproduct-accordion"
              id="accordion-1"
              visible
              accordion="my-accordion"
            >
              <div class="p-4 border-top">
                <form>
                 
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="name">Nombre</label>
                        <input
                          id="name"
                          name="name"
                          v-model="objectData.name"
                          type="text"
                          class="form-control"
                          :class="{
                            'is-invalid': submitted && $v.objectData.name.$error,
                          }"
                          @input=" objectData.slug = slugify(objectData.name)"
                        />
                        <div
                          v-if="submitted && $v.objectData.name.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.objectData.name.required"
                            >Campo requerido</span
                          >
                        </div>
                      </div>
                    </div>
                
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="slug">Slug</label>
                        <input
                          id="slug"
                          name="slug"
                          type="text"
                          class="form-control"
                          required
                          :class="{
                            'is-invalid': submitted && $v.objectData.slug.$error,
                          }"
                          v-model="objectData.slug"
                        />
                        <div
                          v-if="submitted && $v.objectData.slug.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.objectData.slug.required"
                            >Campo requerido</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="name">Precio de envío</label>
                        <input
                          id="name"
                          name="name"
                          v-model="objectData.price"
                          type="number"
                          class="form-control"
                        />
                      
                      </div>
                    </div>
                
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="slug">Mínimo de compra</label>
                        <input
                          id="slug"
                          name="slug"
                          type="number"
                          class="form-control"
                          v-model="objectData.min_total"
                        />
                     
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col text-end ms-1">
        <a  class="btn btn-danger"  @click.prevent="cancelForm()">
          <i class="uil uil-times me-1"></i> Cancelar
        </a>
        <a class="btn btn-success ms-1"  @click.prevent="validateForm()">
          <i class="uil uil-file-alt me-1" ></i> Guardar cambios
        </a>
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>
