export default class {

    constructor(obj = {}) {
        this.init(obj);
    }

  /**
  
   *
   * @param obj
   */
  init(obj = {}) {

       

        this.fillable = {
          id:null,
          name: null,
          slug: null,
          price: null,
          min_total: null
        };

        if (obj) {
            Object.keys(this.fillable).forEach( key => {
                this[ key ] = obj[ key ] || this.fillable[ key ];
            });
        }

    }

    /*castUser(obj) {

      if (obj.hasOwnProperty('name') || obj.hasOwnProperty('slug')) {

        return {
          id:obj.id,
          name: obj.name,
          slug: obj.slug,
          images:obj.images,
          preview: obj.preview,
          description: obj.description,
          include: obj.include,
          seo_title: obj.seo_title,
          seo_description: obj.seo_description,
          price: obj.price,
          price_off: obj.price_off, 
          categories: obj.categories,
          types:obj.types,
          image_front: obj.images.lenght?obj.images[0].path:null,
          status: obj.status
        }

      }

      return obj;

    }*/

   
}
